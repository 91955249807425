import React from 'react';
import {Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Settings from './Settings';
import Name from './Name';


function Up({id,secret,enabled,name,publicPage,isPaidEvent}){
  return(
    <>
      <Row>

        <Col xs={10} className="my-auto">
          <Name name={name} isPaidEvent={isPaidEvent}/>
        </Col>

       {!publicPage &&
         <Col xs={2}>
           <Settings id={id} secret={secret} enabled={enabled}/>
         </Col>
        }

      </Row>
    </>
  );
}

export default Up;
