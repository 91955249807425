import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CloseOnboarding from '../CloseOnboarding';
import Checkout from './Checkout';
import CountdownTimer from './CountdownTimer';
import be from '../../BE';
import auth from '../../Account/Auth';

function PlanFit({plan,setPlan}){
    const history = useHistory();
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        const getPlanFit = async () => {
            try{
                await auth.isAuthenticated()
                    .then(async () => {
                        setLoading(true);
                        await be.get('Onboarding','/onboarding/plan/fit',false,true,true)
                            .then(response => {
                                setPlan(response)
                                console.log("[onboarding][plan-fit] plan fit retrieved");
                            })
                            .catch(error => {
                                console.error("[onboarding][plan-fit] network error while retrieving plan fit:",error);
                            })
                    })
                    .catch((error) => {
                        console.error("[onboarding][plan-fit] authentication error while retrieving plan fit:", error)
                        history.push({pathname:'/login'})
                    })
            }catch (error) { 
                console.error("[onboarding][plan-fit] functional error while retrieving plan fit:",error);
            }finally{
                setLoading(false);
            }
        }
        getPlanFit();
    },[history])

    return(
        <>
            <div className="tw-flex">
                <h1 className="tw-w-full tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 tw-dark:text-white">Special Offer for You!</h1>
                <CloseOnboarding stage="plan-fit"/>
            </div>
            {loading 
                ? 
                    <div className="tw-flex tw-my-40 tw-justify-center" role="status">
                        <svg 
                            aria-hidden="true" 
                            className="tw-inline tw-w-10 tw-h-10 tw-text-gray-200 tw-animate-spin tw-dark:text-gray-600 tw-fill-blue-600" 
                            viewBox="0 0 100 101" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path 
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" 
                                fill="currentColor"
                            />
                            <path 
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" 
                                fill="currentFill"
                            />
                        </svg>
                        <span className="tw-sr-only">Loading...</span>
                    </div>
                :
                    <>
                        <p className="tw-capitalize tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">Calendbook {plan?.plan}</p>
                        <p className="tw-my-4">Use <b className="tw-bg-primary-100 hover:tw-bg-primary-200 tw-text-primary-800 tw-text-sm tw-font-medium tw-px-2.5 tw-py-0.5 tw-rounded dark:tw-bg-gray-700 dark:tw-text-primary-400 tw-border tw-border-primary-400 tw-inline-flex tw-items-center tw-justify-center">{plan?.discountCode}</b> Discount Code to get <b>{plan?.discountAmount}% Off</b> on your yearly subscription.</p>
                        <p className="tw-my-4">Hurry up! this unique discount code is valid for <b><CountdownTimer expirationDate={plan?.expiration}/></b> </p>
                        {plan?.priceId && <Checkout customerId={plan?.customerId} priceId={plan?.priceId} discountId={plan?.discountCode}/>}
                    </>
            }
        </>
    );
}

export default PlanFit;
