import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import be from '../../BE';
import auth from '../../Account/Auth';

function Name({setStage}) {
    const history = useHistory();
    const [eventName, setEventName] = useState('');

    const saveName = async (e) => {
        e.preventDefault();
        const body = {
            eventName:eventName
        }

        try{
            await auth.isAuthenticated()
                .then(() => {
                    be.post('Onboarding','/onboarding/eventName',body,true,true)
                        .then(response => {
                            console.log("[onboarding][event-name] event name saved");
                        })
                        .catch(error => {
                            console.error("[onboarding][event-name] network error while saving event name:",error);
                        })
                })
                .catch((error) => {
                    console.error("[onboarding][event-name] authentication error while saving event name:", error)
                    history.push({pathname:'/login'})
                })
        }catch (error) { 
            console.error("[onboarding][event-name] functional error while saving event name:",error);
        }finally{
            setStage('event-general-availabilities')
        }
    }

    return (
        <form onSubmit={saveName}>
            <h1 className="tw-mb-4 tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 sm:tw-mb-6 tw-dark:text-white">Let's create your first Event!</h1>

            <p className="tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">What is the Name of your Event?</p>

            <input
                type="text"
                name="full-name"
                id="full-name"
                className="tw-bg-gray-50 !tw-border-2 tw-border-gray-200 tw-text-gray-900 tw-rounded-lg focus:!tw-ring-primary-600 focus:!tw-border-primary-600 tw-w-full tw-p-5 tw-text-gray-500 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:hover:bg-gray-700"
                placeholder="15 min for clients"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                required
            />
            <button
                type="sumbit"
                className="tw-my-6 tw-w-full tw-px-5 tw-py-2.5 sm:tw-py-3.5 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
            >
                Next: Event Availability
            </button>
        </form>
    );
}

export default Name;
