import React from 'react';
import { useTranslation } from 'react-i18next';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import be from '../BE';
import auth from '../Account/Auth';

// TODO: renderizzare senza fare il reload della pagina
// al momento c'è un href="/" su tutti i button

async function deleteEvent(key_param){
  await auth.isAuthenticated()
  .then(() => {
    const body = { key : key_param }
    be.del('eventtypes','/eventypes',body,true)
    .then(response => window.location.href = '/')
    .catch(error => console.log(error));
  })
  .catch(() => this.props.history.push('/login'));
}

async function secretEvent(key_param){
  await auth.isAuthenticated()
  .then(() => {
    const body = { key : key_param }
    be.post('eventtypes','/secret',body,true)
    .then(response => window.location.href = '/')
    .catch(error => console.log(error));
  })
  .catch(() => this.props.history.push('/login'));
}

async function enableEvent(key_param){
  await auth.isAuthenticated()
  .then(() => {
    const body = { key : key_param }
    be.post('eventtypes','/enable',body,true)
    .then(response => window.location.href = '/')
    .catch(error => console.log(error));
  })
  .catch(() => this.props.history.push('/login'));
}

function Settings({id,secret,enabled}){
  const { t } = useTranslation(['eventType']);
  return(
    <>
      <DropdownButton id="dropdown-basic-button" title={<i className="fas fa-ellipsis-h" style={{color:"var(--color-text)"}}></i>} className="float-end">
        <Dropdown.Item href="#" onClick={async () => {await secretEvent(id)}}>{secret ? t('settings.setPublic') : t('settings.setPrivate')}</Dropdown.Item>
        <Dropdown.Item href="#" onClick={async () => {await enableEvent(id)}}>{enabled ? t('settings.disable') : t('settings.enable')}</Dropdown.Item>
        <Dropdown.Item href="#" onClick={async () => {await deleteEvent(id)}}>{t('settings.delete')}</Dropdown.Item>
      </DropdownButton>
    </>
  );
}

export default Settings;
