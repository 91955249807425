import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Hop from './Hop';
import BookingLimit from './BookingLimit';
import Notice from './Notice';
import LastAvailable from './LastAvailable';
import Approval from './Approval';
import AvailabilityMock from '../../Mocks/EventTypeCreator/Availability';
import be from '../../BE';
import auth from '../../Account/Auth';

function AdvancedAvailabilities({ setStage }){
    const history = useHistory();
    const [availability, setAvailability] = useState(AvailabilityMock)

    const saveAdvancedAvailabilities = async () => {
        const body = {
            availability:{
                hop: availability?.hop,
                bookingLimit: availability?.bookingLimit,
                notice: availability?.notice,
                lastAvailability: availability?.lastAvailability,
                needsApproval: availability?.needsApproval
            }
        }

        try{
            await auth.isAuthenticated()
                .then(() => {
                    be.post('Onboarding','/onboarding/availabilities/advanced',body,true,true)
                        .then(response => {
                            console.log("[onboarding][advanced-availabilities] advanced availabilities saved");
                        })
                        .catch(error => {
                            console.error("[onboarding][advanced-availabilities] network error while saving advanced availabilities:",error);
                        })
                })
                .catch((error) => {
                    console.error("[onboarding][advanced-availabilities] authentication error while saving advanced availabilities:", error)
                    history.push({pathname:'/login'})
                })
        }catch (error) { 
            console.error("[onboarding][advanced-availabilities] functional error while saving advanced availabilities:",error);
        }finally{
            setStage('notifications')
        }
    }

    // Update the timeslot hop value in the availability state
    const setTimeslotHop = (_hop) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            hop: _hop // Directly update the hop value
        }));
    };

    // Update the booking limit quantity in the availability state
    const setBookingLimitQuantity = (_quantity) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            bookingLimit: {
                ...prevAvailability.bookingLimit, // Ensure deep cloning of nested objects
                quantity: _quantity // Update the quantity for booking limit
            }
        }));
    };

    // Update the booking limit unit in the availability state
    const setBookingLimitUnit = (_unit) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            bookingLimit: {
                ...prevAvailability.bookingLimit, // Ensure deep cloning of nested objects
                unit: _unit // Update the unit for booking limit
            }
        }));
    };

    // Update the notice quantity in the availability state
    const setNoticeQuantity = (_quantity) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            notice: {
                ...prevAvailability.notice, // Ensure deep cloning of nested objects
                quantity: _quantity // Update the quantity for notice
            }
        }));
    };

    // Update the notice unit in the availability state
    const setNoticeUnit = (_unit) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            notice: {
                ...prevAvailability.notice, // Ensure deep cloning of nested objects
                unit: _unit // Update the unit for notice
            }
        }));
    };

    // Update the last availability date in the availability state
    const setLastAvailabilityDate = (_date) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            lastAvailability: {
                ...prevAvailability.lastAvailability, // Ensure deep cloning of nested objects
                date: _date // Update the date for last availability
            }
        }));
    };

    // Update the last availability time in the availability state
    const setLastAvailabilityTime = (_time) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            lastAvailability: {
                ...prevAvailability.lastAvailability, // Ensure deep cloning of nested objects
                time: _time // Update the time for last availability
            }
        }));
    };

    // Update the approval requirement in the availability state
    const setApproval = (_needsApproval) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            needsApproval: _needsApproval // Update the approval requirement
        }));
    };


    return(
        <>
            <h1 className="tw-mb-4 tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 sm:tw-mb-6 tw-dark:text-white">Let's create your first Event!</h1>

            <p className="tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">How will you be scheduled?</p>

            <Hop availability={availability} setTimeslotHop={(hop) => setTimeslotHop(hop)} />
            <BookingLimit availability={availability} setBookingLimitQuantity={(quantity) => setBookingLimitQuantity(quantity)} setBookingLimitUnit={(unit) => setBookingLimitUnit(unit)} />
            <Notice availability={availability} setNoticeQuantity={(quantity) => setNoticeQuantity(quantity)} setNoticeUnit={(unit) => setNoticeUnit(unit)} />
            <LastAvailable availability={availability} setLastAvailabilityDate={(date) => setLastAvailabilityDate(date)} setLastAvailabilityTime={(time) => setLastAvailabilityTime(time)} />
            <Approval availability={availability} setApproval={(needsApproval) => setApproval(needsApproval)} />

            <button
                type="button"
                onClick={saveAdvancedAvailabilities}
                className="tw-my-6 tw-w-full tw-px-5 tw-py-2.5 sm:tw-py-3.5 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
            >
                Next: Notifications
            </button>

        </>
    );
}

export default AdvancedAvailabilities;
