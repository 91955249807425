import React from 'react';
import { useTranslation } from 'react-i18next';
import {Row,Col,Dropdown,Navbar,Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import auth from '../Account/Auth';
import be from '../BE'; 

// only update language on be if user is logged
async function setUserLanguage(language){
  await auth.isAuthenticated()
  .then(async () => {
    await be.post('account',"/setOwnerLanguage",{language:language},true)
    .then(() => auth.refresh())
    .catch((error) => console.log(error));
  })
  .catch(()=>{})
}

function getFlag(language){
  // getting only first 2 chars of the language (it, en, ...)
  language = language.toLowerCase().substring(0,2)
  if(language === 'it') return <span role="img" aria-label="italiano" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png" alt="italiano" width="16px"/> it</span>
  if(language === 'en') return <span role="img" aria-label="english" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png" alt="english" width="16px"/> en</span>
  if(language === 'es') return <span role="img" aria-label="español" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png" alt="español" width="16px"/> es </span>
  if(language === 'de') return <span role="img" aria-label="deutsch" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png" alt="deutsch" width="16px"/> de </span>
  if(language === 'fr') return <span role="img" aria-label="francais" className="d-inline-flex align-items-center gap-2"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png" alt="francais" width="16px"/> fr </span>
}

function _Footer(props){
  const Logo = window.calendbookUI.LogoFull;
  const { i18n } = useTranslation();
  const switchLng = async (lng) => {i18n.changeLanguage(lng); await setUserLanguage(lng)}
  const languageToggle = React.forwardRef(({children,onClick},ref) => (
    <a 
      className="text-white d-inline-flex align-items-center gap-1"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span style={{fontSize:12}}>&#x25bc;</span>
    </a>
  ));

  return(
    <>
        <Row className="navbar navbar-dark position-absolute static-bottom w-100 mx-0" style={{backgroundColor:"#242526",bottom:'0',height:'500px'}}>
        <Container className="px-4 px-md-5">
        <Col xs={12} md={5} lg={6} className="mt-sm-auto mb-sm-0 my-md-auto">
          <Row xs={12} className="my-3 my-lg-5 w-100">
            <Col xs={6} className="my-auto">
              <Link to='/'>
                <Logo width="164px"/>
              </Link>
            </Col>
            <Col xs={6} className="my-auto">
                <Dropdown id="bg-nested-dropdown">
                  <Dropdown.Toggle as={languageToggle} id="dropdown-toggle">
                    {getFlag(i18n.language)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{width:160}}>
                    <Dropdown.Item className="d-inline-flex gap-2 align-items-center text-capitalize" onClick={() => switchLng('it')}><span role="img" aria-label="italiano"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ee-1f1f9.png" alt="italiano" width="16px"/></span> italiano</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex gap-2 align-items-center text-capitalize" onClick={() => switchLng('en')}><span role="img" aria-label="english"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1fa-1f1f8.png" alt="english" width="16px"/></span> english</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex gap-2 align-items-center text-capitalize" onClick={() => switchLng('es')}><span role="img" aria-label="español"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1ea-1f1f8.png" alt="español" width="16px"/></span> español</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex gap-2 align-items-center text-capitalize" onClick={() => switchLng('de')}><span role="img" aria-label="deutsch"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1e9-1f1ea.png" alt="deutsch" width="16px"/></span> deutsch</Dropdown.Item>
                    <Dropdown.Item className="d-inline-flex gap-2 align-items-center text-capitalize" onClick={() => switchLng('fr')}><span role="img" aria-label="francais"><img src="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f1eb-1f1f7.png" alt="francais" width="16px"/></span> francais</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </Col>
          </Row>
          <Row sm={12} className="mt-5">
            <Col className="">
              <p className="text-light text-uppercase fw-bold">
                Menu
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://blog.calendbook.com/about/">About us</a>
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://www.calendbook.com/integrations">Integrations</a>
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://trello.com/invite/b/WWrW7CDX/c95a3ab0e066f915537eeb3db514f934/public-calendbookcom-roadmap">Roadmap</a>
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://medium.com/calendbook">Blog</a>
              </p>
            </Col>
            <Col>
              <p className="text-light text-uppercase fw-bold">
                Social Links
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/calendbook"><i className="fab fa-linkedin me-2"></i>Linkedin</a>
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://twitter.com/calendbook"><i className="fab fa-twitter me-2"></i>Twitter</a>
              </p>
              <p>
                <a className="text-light" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCFqsHBf0DgZ3L7aBjNb-ZCA"><i className="fab fa-youtube me-2"></i>YouTube</a>
              </p>
            </Col>
            <Col>
              <p className="text-center">
              <a style={{marginBottom:10}} href="https://www.capterra.com/reviews/343567/Calendbook?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"> <img style={{width:100}} border="0" src="https://assets.capterra.com/badge/c61e3f861e4ae35d055bb5967e386481.svg?v=2290534&p=343567" /> </a>
              </p>
              <p className="text-center">
              <a href="https://www.capterra.com/p/343567/Calendbook/"> <img style={{width:70}} border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ca-value-2023.png" /> </a>
              </p>
              <p className="text-center">
              <a href="https://www.capterra.com/p/343567/Calendbook/"> <img style={{width:70}} border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ca-ease_of_use-2023.png" /> </a>
              </p>
            </Col>
          </Row>
        </Col>
          <Col sm={12} style={{color:'var(--color-secondary)',textDecoration:'none'}}>
            <Row className="my-2 justify-content-center">
              <Col xs={4} md={2} className="my-auto text-center" style={{fontSize:'12px'}}>
              <Link style={{color:'var(--color-secondary)',textDecoration:'none'}} to="/">Calendbook &copy; {new Date().getFullYear()}</Link>
            </Col>
            <Col xs={4} md={2} className="my-auto text-center" style={{fontSize:'12px'}}>
              <Link style={{color:'var(--color-secondary)',textDecoration:'none'}} to='/privacy'>Privacy Policy</Link>
            </Col>
            <Col xs={4} md={2} className="my-auto text-center" style={{fontSize:'12px'}}>
              <Link style={{color:'var(--color-secondary)',textDecoration:'none'}} to='/terms'>Terms & Conditions</Link>
            </Col>
            <Col xs={4} md={2} className="my-auto text-center" style={{fontSize:'12px'}}>
              <Link style={{color:'var(--color-secondary)',textDecoration:'none'}} to='/refund'>Refund Policy</Link>
            </Col>
            <Col xs={4} md={2} className="my-auto text-center" style={{fontSize:'12px'}}>
              <span>p. iva: 08486710729</span>
            </Col>
          </Row>
        </Col>
          </Container>
      </Row>
    </>
  );
}

export default _Footer;
