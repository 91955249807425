import React, { useState, useEffect } from "react";
import { useIframeStore } from "../store";
import CloseOnboarding from "./CloseOnboarding";
import User from "./User/User";
import Name from "./Event/Name";
import GeneralAvailabilities from "./Event/GeneralAvailabilities";
import AdvancedAvailabilities from "./Event/AdvancedAvailabilities";
import Notifications from "./Notifications/Notifications";
import PlanFit from "./PlanFit/PlanFit";

function Onboarding() {
  // --- STAGES ---
  // user: asks user info as fullname, industry, teamsize
  // event-name: asks first event name
  // event-general-availabilities: asks for days ranges
  // event-advanced-availabilities: asks for premium availabilities features
  // notifications: asks for notifications preferred provider
  // plan-fit: last step, suggest the user the best plan for his choices
  const [stage, setStage] = useState("user");
  const [plan, setPlan] = useState({});
  const Logo = window.calendbookUI.LogoFull;
  const features = {
    premium: [
      "Unlimited Event Types",
      "10 Calendar Connections",
      "Zoom Integration",
      "Questions to Qualify Leads",
      "Send Emails from your Gmail",
    ],
    professional: [
      "Zapier & Webhook Integrations",
      "Advanced Availability",
      "Company Branding",
      "Accept/Reject Schedulings",
      "Whatsapp & Telegram Notifications",
    ],
  };

  useEffect(() => {
    document.title = "Calendbook - Onboarding";
  }, []);

  // sets forced white theme
  const root = document.getElementById("root");
  const html = document.getElementById("html");
  root.setAttribute("data-theme", "light");
  html.setAttribute("data-theme", "light");

  // hide navbar and footer
  const setShouldShowNavs = useIframeStore((state) => state.setShouldShowNavs);
  setShouldShowNavs(false);
  document.getElementById("root").style.padding = 0;
  document.getElementById("root").style.minHeight = 0;

  return (
    <>
      <section className="tw-h-screen tw-bg-white tw-dark:bg-gray-900 lg:tw-py-0">
        <div className="lg:tw-flex">
          {stage === "plan-fit" && (
            <div className="tw-hidden tw-w-full tw-max-w-md tw-p-12 lg:tw-h-screen lg:tw-block tw-bg-primary-600">
              <div className="tw-flex tw-items-center tw-mb-8 tw-space-x-4">
                <Logo fillColor="white" />
              </div>
              <div className="tw-block tw-p-8 tw-text-white tw-rounded-lg tw-bg-primary-500">
                <CloseOnboarding stage={stage} />
                <h2 className="tw-mb-4 tw-text-2xl tw-font-semibold">
                  Calendbook <span className="tw-capitalize">{plan?.plan}</span>
                </h2>
                <ul role="list" className="tw-space-y-4 tw-text-left">
                  {plan?.plan &&
                    features[plan?.plan].map((feature) => (
                      <li className="tw-flex tw-items-center tw-space-x-3">
                        <svg
                          className="tw-flex-shrink-0 tw-w-5 tw-h-5 tw-text-green-300"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>{feature}</span>
                      </li>
                    ))}
                  <li className="tw-flex tw-items-center tw-space-x-3">
                    <span className="tw-ml-8">and more...</span>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div className="tw-flex tw-items-center tw-mx-auto md:tw-w-[50rem] tw-px-4 md:tw-px-8 xl:tw-px-0">
            <div className="tw-w-full">
              {/* Logo */}
              {stage !== "plan-fit" && (
                <div className="tw-flex tw-items-center tw-justify-center tw-my-8 tw-space-x-4 lg:tw-hidden">
                  <a
                    href="#"
                    className="tw-flex tw-items-center tw-text-2xl tw-font-semibold"
                  >
                    <Logo />
                  </a>
                </div>
              )}

              {/* Breadcrumbs */}
              {stage !== "plan-fit" && (
                <ol className="tw-flex tw-items-center tw-mb-6 tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 tw-dark:text-gray-400 lg:tw-mb-12 sm:tw-text-base">
                  <li className="tw-flex tw-items-center after:tw-content-[''] after:tw-w-12 after:tw-h-1 after:tw-border-b after:tw-border-gray-200 after:tw-border-1 after:tw-hidden sm:after:tw-inline-block after:tw-mx-6 xl:after:tw-mx-10 tw-dark:after:tw-border-gray-700">
                    <div
                      className={`
                                            ${
                                              stage === "user" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "event-name" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage ===
                                                "event-general-availabilities" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage ===
                                                "event-advanced-availabilities" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "notifications" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "plan-fit" &&
                                              "tw-text-primary-500"
                                            }
                                            tw-flex tw-items-center sm:tw-block after:tw-content-['/'] sm:after:tw-hidden after:tw-mx-2 after:tw-font-light after:tw-text-gray-200 tw-dark:after:tw-text-gray-500`}
                    >
                      <div className="tw-mr-2 sm:tw-mb-0 sm:tw-mx-2">1</div>
                      Personal{" "}
                      <span className="tw-ml-1 tw-hidden sm:tw-inline-flex">
                        Info
                      </span>
                    </div>
                  </li>
                  <li className="tw-flex tw-items-center after:tw-content-[''] after:tw-w-12 after:tw-h-1 after:tw-border-b after:tw-border-gray-200 after:tw-border-1 after:tw-hidden sm:after:tw-inline-block after:tw-mx-6 xl:after:tw-mx-10 tw-dark:after:tw-border-gray-700">
                    <div
                      className={`
                                            ${
                                              stage === "event-name" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage ===
                                                "event-general-availabilities" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage ===
                                                "event-advanced-availabilities" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "notifications" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "plan-fit" &&
                                              "tw-text-primary-500"
                                            }
                                            tw-flex tw-items-center sm:tw-block after:tw-content-['/'] sm:after:tw-hidden after:tw-mx-2 after:tw-font-light after:tw-text-gray-200 tw-dark:after:tw-text-gray-500`}
                    >
                      <div className="tw-mr-2 sm:tw-mb-0 sm:tw-mx-2">2</div>
                      Event{" "}
                      <span className="tw-ml-1 tw-hidden sm:tw-inline-flex">
                        Creation
                      </span>
                    </div>
                  </li>
                  <li className="tw-flex tw-items-center sm:tw-block">
                    <div
                      className={`
                                            ${
                                              stage === "notifications" &&
                                              "tw-text-primary-500"
                                            }
                                            ${
                                              stage === "plan-fit" &&
                                              "tw-text-primary-500"
                                            }
                                            tw-flex tw-items-center sm:tw-block after:tw-content-['/'] sm:after:tw-hidden after:tw-mx-2 after:tw-font-light after:tw-text-gray-200 tw-dark:after:tw-text-gray-500`}
                    >
                      <div className="tw-ml-1 tw-mr-2 sm:tw-mb-0 sm:tw-mx-2">
                        3
                      </div>
                      Notifications
                    </div>
                  </li>

                  <CloseOnboarding stage={stage} />
                </ol>
              )}

              {stage === "user" && <User setStage={setStage} />}
              {stage === "event-name" && <Name setStage={setStage} />}
              {stage === "event-general-availabilities" && (
                <GeneralAvailabilities setStage={setStage} />
              )}
              {stage === "event-advanced-availabilities" && (
                <AdvancedAvailabilities setStage={setStage} />
              )}
              {stage === "notifications" && (
                <Notifications setStage={setStage} />
              )}
              {stage === "plan-fit" && (
                <PlanFit plan={plan} setPlan={setPlan} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Onboarding;
