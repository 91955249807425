import {useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {Amplify,Auth} from 'aws-amplify';
import spacetime from 'spacetime';
import BE from '../../BE';
import './passwordless.css';

// printing console.info logs only in localhost
const DEBUG = window.location.hostname === 'localhost';
var CLIENT = ""
if(DEBUG){CLIENT = "8oqrf7a5het2vkb0u06km15us"}
else{CLIENT = "4de86ece06uce62qeoqvsfkuef"}

function Passwordless({setLoggingProvider}){
  const { i18n,t } = useTranslation(["passwordless"]);
  const getUserLanguage = () => { return i18n.language.substring(0,2) }

  Amplify.configure({
    Auth:{
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_j3ieIXUaN',
      userPoolWebClientId: CLIENT,
      mandatorySignIn: true
    }
  })

  const [email,setEmail] = useState("");
  const [urlEmail,setUrlEmail] = useState("");
  const [urlToken,setUrlToken] = useState("");
  const [loading,setLoading] = useState(false); 
  const [status,setStatus] = useState(0);
  // --- STAGES ---
  // signIn: Email recognition
  //  signInSuccess: Email xxx recognized
  //  signInError: Error while recognizing xxx email
  // challenge: Authenticating
  //  challengeSuccess: Authenticated
  //  challengError: Authentication Error
  // getUser: Loading your data
  //  getUserSuccess: User data loaded, logging in
  //  getUserError: Error while getting user data
  const [stage,setStage] = useState("")
  let cognitoUser;

  useEffect(() => {
    const loadCustomChallenge = async () => {
      if (window.location.search) {
        const qs = window.location.search.substring(1)
        const qsParams = qs.split(['&'])
        const qsEmail = qsParams.find(x => x.startsWith('email='))
        const qsToken = qsParams.find(x => x.startsWith('token='))
        if (qsToken) {
          setLoading(true);
          (setLoggingProvider && setLoggingProvider("passwordless"))
          DEBUG && console.info(`[passwordless] calling signIn for ${urlEmail}...`)
          setStage("signIn");
          setUrlEmail(decodeURIComponent(qsEmail.substring(6)).toLowerCase())
          await signIn(decodeURIComponent(qsEmail.substring(6)).toLowerCase())

          DEBUG && console.info(`[passwordless] calling customChallenge for ${urlEmail} with token...`)
          setStage("challenge");
          setUrlToken(decodeURIComponent(qsToken.substring(6)))
          await answerCustomChallenge(decodeURIComponent(qsToken.substring(6)))
          setLoading(false);
          (setLoggingProvider && setLoggingProvider("none"));
        }
      }
    }
    loadCustomChallenge()
  },[])

  const signIn = async (_email) => {
    try{
      cognitoUser = await Auth.signIn(_email);
      DEBUG && console.info(`%c[passwordless] signed in ${_email} with cognitoUser response`,'color:lightgreen')
      setStage("signInSuccess");
    }catch(e){
      DEBUG && console.info(`%c[passwordless][error] unable to sign in ${_email} with cognitoUser response: ${e?.message}`,'color:salmon')
      setStage("signInError");
    }
  }

  async function answerCustomChallenge(_token) {
    try {
      const challengeResult = await Auth.sendCustomChallengeAnswer(cognitoUser, _token)
      DEBUG && console.info(`%c[passwordless] customChallenge success for ${challengeResult?.username}`,'color:lightgreen')
      DEBUG && console.info(`[passwordless] calling BE for ${challengeResult?.username} profileObj...`)
      setStage("challengeSuccess");
      setStage("getUser");
      await getUserInfo(createAccessObj(challengeResult))
    } catch (e) {
      DEBUG && console.info(`%c[passwordless][error] unable to resolve CustomChallenge: ${e?.message}`,'color:salmon')
      setStage("challengeError")
    }
  }

  const createAccessObj = (_challengeResult) => {
    const access = _challengeResult?.signInUserSession?.accessToken?.jwtToken;
    const refresh = _challengeResult?.signInUserSession?.refreshToken?.token;
    const accessObj = {access_token:access,refresh_token:refresh};
    return accessObj;
  }

  const getUserInfo = async (_accessObj) => {
    localStorage.setItem('userSession', JSON.stringify({access:_accessObj}));
    await BE.get("account-jubilant","/account/me",false,true,true)
      .then(response => {
        localStorage.setItem('userSession', JSON.stringify({access:_accessObj,profileObj:response}));
        DEBUG && console.info("%c[passwordless] built localStorage userSession, redirecting...",'color:lightgreen');
        setStage("getUserSuccess");
        // redirect to onboarding page if profile obj indicates that the user needs onboarding
        // otherwise, redirect to dashboard page
        if(response?.needsOnboard){
          window.location.href = '/onboarding'
        }else{
          window.location.href = '/';
        }
      })
      .catch(e=> {
        DEBUG && console.info(`%c[passwordless][error] unable to set localStorage userSession: ${e?.message}`,'color:salmon');
        setStage("getUserError");
        localStorage.removeItem('userSession');
        DEBUG && console.info("[passwordless] cleaned userSession localStorage")
      })
  }

  const sendMagicLink = async (e) => {
    if(!isValidEmail()){
      e.preventDefault();
      e.stopPropagation();
    }else{
      e.preventDefault();
      setLoading(true);
      setStatus(0);
      const timezone = spacetime.now().timezone().name;
      const language = getUserLanguage();
      const body = {email,timezone,language}
      DEBUG && console.info(`[passwordless] requesting magic link for ${email}...`)
      await BE.post("account-jubilant","/account/login/magicLink",body,false,true,true)
        .then(response => {
          setStatus(response?.status);
          if(response?.status === 200){
            DEBUG && console.info(`%c[passwordless] sent magic link to ${email}`,'color:lightgreen')
          }
          else{
            DEBUG && console.info(`%c[passwordless][error] received response unrecognized response status: ${response?.status}`,'color:salmon')
          }
        })
        .catch(error => {
          setStatus(error?.response?.status)
          DEBUG && console.info(`%c[passwordless][error] unable to send magic link: ${error?.response.status} - ${error?.response?.statusText}`,'color:salmon')
        })
      setLoading(false);
    }

  }

  const isValidEmail = () => {
    if(urlEmail){
      return true;
    }else{
      if(email){
        const regex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/i
        return regex.test(email);
      }else{
        return true;
      }
    }
  }

  return(
    <div className="passwordless-container">
      <div className="separator">or</div>
      <div className="d-inline-flex align-items-baseline">
        <h6>{t("title")}</h6>
        <OverlayTrigger  overlay={<Tooltip id="magic-link-explanation" className="magic-link-tooltip"> &#128236; {t("tooltip")}</Tooltip>}>
          <i className="fa-regular fa-circle-question ms-2"></i>
        </OverlayTrigger>
      </div>
      <Form noValidate validate={isValidEmail()} onSubmit={sendMagicLink}>
        <InputGroup hasValidation>
          <FormControl
            disabled={loading || urlEmail}
            name="passwordless-email"
            placeholder="your.email@provider.com"
            id="passwordless-email-field"
            aria-describedby="passwordless-email"
            value={urlEmail || email}
            onChange={(e) => {setEmail(e.target.value.toLowerCase());setStatus(0);}}
            isInvalid={!isValidEmail()}
          />
          <Button disabled={loading || urlEmail} type='submit'>
            {loading 
              ? 
                <Spinner size="sm" animation="border" variant="light" /> 
                : 
                <i className="fas fa-long-arrow-alt-right"></i>
            }
          </Button>
          <Form.Control.Feedback type="invalid">
            <span className="feedback invalid">{t("feedbacks.invalidRegex")}</span>
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
      {(status >= 400 && status <= 500) && <p className="feedback invalid">{t("feedbacks.statusError",{status})}</p>}
      {(status === 200) && <p className="feedback valid">{t("feedbacks.statusSuccess")}</p>}
      {(stage === "signIn") && <p className="feedback">{t("feedbacks.stageSignIn")}</p>}
      {(stage === "signInSuccess") && <p className="feedback valid">{t("feedbacks.stageSignInSuccess")}</p>}
      {(stage === "signInError") && <p className="feedback invalid">{t("feedbacks.stageSignInError")}</p>}
      {(stage === "challenge") && <p className="feedback">{t("feedbacks.stageChallenge")}</p>}
      {(stage === "challengeSuccess") && <p className="feedback valid">{t("feedbacks.stageChallengeSuccess")}</p>}
      {(stage === "challengeError") && <p className="feedback invalid">{t("feedbacks.stageChallengeError")}</p>}
      {(stage === "getUser") && <p className="feedback">{t("feedbacks.stageGetUser")}</p>}
      {(stage === "getUserSuccess") && <p className="feedback valid">{t("feedbacks.stageGetUserSuccess")}</p>}
      {(stage === "getUserError") && <p className="feedback invalid">{t("feedbacks.stageGetUserError")}</p>}
    </div>
  );
}

export default Passwordless;
