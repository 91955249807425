import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import be from '../../BE';
import auth from '../../Account/Auth';

function User({setStage}) {
    const history = useHistory();
    const [fullName, setFullName] = useState('');
    const [industry, setIndustry] = useState('');
    const [teamSize, setTeamSize] = useState('');

    const saveUser = async (e) => {
        e.preventDefault()
        const body = {
            fullName:fullName,
            industry:industry,
            teamSize:teamSize
        }

        try{
            await auth.isAuthenticated()
                .then(() => {
                    be.post('Onboarding','/onboarding/fullname',body,true,true)
                        .then(response => {
                            console.log("[onboarding][user] user saved");
                        })
                        .catch(error => {
                            console.error("[onboarding][user] network error while saving user:",error);
                        })
                })
                .catch((error) => {
                    console.error("[onboarding][user] authentication error while saving user:", error)
                    history.push({pathname:'/login'})
                })
        }catch (error) { 
            console.error("[onboarding][user] functional error while saving user:",error);
        }finally{
            setStage('event-name')
        }
    }

    return (
        <form onSubmit={saveUser}>
            <h1 className="tw-mb-4 tw-text-2xl tw-font-extrabold tw-leading-tight tw-tracking-tight tw-text-gray-900 sm:tw-mb-6 tw-dark:text-white">Tell us about yourself</h1>

            <p className="tw-mb-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">What is your Full Name?</p>

            <input
                type="text"
                name="full-name"
                id="full-name"
                className="tw-bg-gray-50 !tw-border-2 tw-border-gray-200 tw-text-gray-900 tw-rounded-lg focus:!tw-ring-primary-600 focus:!tw-border-primary-600 tw-w-full tw-p-5 tw-text-gray-500 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:bg-gray-800 tw-dark:border-gray-700 tw-dark:placeholder-gray-400 tw-dark:text-white tw-dark:hover:bg-gray-700"
                placeholder="Bonnie"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
            />

            <p className="tw-my-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">What is your Role?</p>

            <ul className="tw-grid tw-grid-cols-3 tw-gap-6 tw-mb-6">
                <li className="">
                    <input type="radio" id="designer" name="profession" value="designer" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('design')} htmlFor="designer" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0L160 0c17.7 0 32 14.3 32 32l0 384c0 53-43 96-96 96s-96-43-96-96L0 32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9l0-262 75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192L480 320c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-297.2 0zM128 64L64 64l0 64 64 0 0-64zM64 192l0 64 64 0 0-64-64 0zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                        <span className="tw-w-full">Designer</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="developer" name="profession" value="developer" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('developer')} htmlFor="developer" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
                        <span className="tw-w-full">Developer</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="sales" name="profession" value="sales" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('sales')} htmlFor="sales" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M160 0c17.7 0 32 14.3 32 32l0 35.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11l0 33.4c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.9c-.4-.1-.9-.1-1.3-.2l-.2 0s0 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7s0 0 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11L128 32c0-17.7 14.3-32 32-32z"/></svg>
                        <span className="tw-w-full">Sales</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="marketing" name="profession" value="marketing" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('marketing')} htmlFor="marketing" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75l-8.7 0-32 0-96 0c-35.3 0-64 28.7-64 64l0 96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-128 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4L480 32zm-64 76.7L416 240l0 131.3C357.2 317.8 280.5 288 200.7 288l-8.7 0 0-96 8.7 0c79.8 0 156.5-29.8 215.3-83.3z"/></svg>
                        <span className="tw-w-full">Marketing</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="customer-care" name="profession" value="customer-care" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('customer-care')} htmlFor="customer-care" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M559.7 392.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416 272 416c-8.8 0-16-7.2-16-16s7.2-16 16-16l16 0 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0-16 0-78.3 0c-29.1 0-57.3 9.9-80 28L68.8 384 32 384c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l160 0 160.5 0c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2c.3 0 .6 0 .9 0c0 0 0 0 0 0c-.3 0-.6 0-.9 0z"/></svg>
                        <span className="tw-w-full">Customer Care</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="recruiting" name="profession" value="recruiting" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('recruiting')} htmlFor="recruiting" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7l131.7 0c0 0 0 0 .1 0l5.5 0 112 0 5.5 0c0 0 0 0 .1 0l131.7 0c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2L224 304l-19.7 0c-12.4 0-20.1 13.6-13.7 24.2z"/></svg>
                        <span className="tw-w-full">Recruiting</span>
                    </label>
                </li>
                <li className="">
                    <input type="radio" id="other" name="profession" value="other" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setIndustry('other')} htmlFor="other" className="tw-inline-flex tw-items-center tw-justify-center tw-w-full tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <svg className="tw-w-4 tw-h-4 tw-mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
                        <span className="tw-w-full">Other</span>
                    </label>
                </li>

            </ul>

            <p className="tw-my-4 tw-text-lg tw-font-light tw-text-gray-500 tw-dark:text-gray-400">How many of you?</p>

            <ul className="tw-mb-6 tw-gap-6 tw-inline-flex">
                <li>
                    <input type="radio" id="just-me" name="team-size" value="just-me" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setTeamSize('just-me')} htmlFor="just-me" className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <span className="tw-w-full">Just me</span>
                    </label>
                </li>
                <li>
                    <input type="radio" id="2-10" name="team-size" value="2-10" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setTeamSize('2-10')} htmlFor="2-10" className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <span className="tw-w-full">2-10</span>
                    </label>
                </li>
                <li>
                    <input type="radio" id="11-50" name="team-size" value="11-50" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setTeamSize('11-50')} htmlFor="11-50" className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <span className="tw-w-full">11-50</span>
                    </label>
                </li>
                <li>
                    <input type="radio" id="50+r" name="team-size" value="50+" className="!tw-hidden tw-peer"/>
                    <label onClick={() => setTeamSize('50+')} htmlFor="50+" className="tw-inline-flex tw-items-center tw-justify-center tw-w-auto tw-p-5 tw-text-gray-500 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-cursor-pointer tw-dark:hover:text-gray-300 tw-dark:border-gray-700 tw-dark:peer-checked:text-primary-500 peer-checked:tw-border-primary-600 peer-checked:tw-text-primary-600 tw-bg-gray-50 hover:tw-text-gray-600 hover:tw-bg-gray-100 tw-dark:text-gray-400 tw-dark:bg-gray-800 tw-dark:hover:bg-gray-700">
                        <span className="tw-w-full">50+</span>
                    </label>
                </li>
            </ul>

            <button
                type="submit"
                className="tw-w-full tw-px-5 tw-py-2.5 sm:tw-py-3.5 tw-text-sm tw-font-medium tw-text-center tw-text-white tw-rounded-lg tw-bg-primary-600 hover:tw-bg-primary-700 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-primary-300 tw-dark:bg-primary-600 tw-dark:hover:bg-primary-700 tw-dark:focus:tw-ring-primary-800"
            >
                Next: Event Creation
            </button>
        </form>
    );
}

export default User;
